import ShManifestTable from "@/views/ShanghaiManifest/components/ShManifestTable.vue";
import AddManifestDialog from "@/views/ShanghaiManifest/components/AddManifestDialog.vue";
import DeleteManifestDialog from "@/views/ShanghaiManifest/components/DeleteMainfestDialog.vue";
import ManifestSearchBar from "@/views/ShanghaiManifest/components/ManifestSearchBar.vue";
import {queryShowList} from "@/api/shanghaiManifest";
import {nanoid} from 'nanoid';

export default {
    components: {
        ShManifestTable,
        AddManifestDialog,
        DeleteManifestDialog,
        ManifestSearchBar
    },
    data() {
        return {
            fileList: [],
            //table 数据
            currPage: 1,
            totalPage: 0,
            pageSize: 10,
            totalCount: 0,
            exportDialogVisible: false,
            tableHeaders: this.RESETTABLE.manifestTableTitleList.filter(x => !x.isSend), //列表标题
            tableData: [],
            multipleSelection: [], //多选中的信息
            search_completionstatus: 0, //搜索的舱单状态
            search: {}//搜索框条件，接收SearchBar的返回参数
        }
    },
    mounted() {
        this.init();
    },
    computed: {},
    methods: {
        init() {
            this.getList();
        }, //初始化界面
        searchList(data) {
            this.search = data
            this.getList()
        },
        pageSizeChange(size){
            this.pageSize = size;
            this.currPage = 1;
            this.getList();
        },
        nextPage(page) {
            this.currPage = page;
            this.getList();
        },
        getList() {
            let data = {
                ...this.search,
                id: this.$store.state.user.userId,
                completionstatus: this.search_completionstatus,
                page: this.currPage,
                limit: this.pageSize,
            }
            // this.$nextTick(() => {
            //   this.$refs.ShManifestTable.init(this.tableData, this.tableHeaders, 0)
            // })
            queryShowList(data)
                .then((response) => {
                    if (response.data.page) {
                        this.totalPage = response.data.page.totalPage;
                        this.totalCount = response.data.page.totalCount;
                        this.currPage = response.data.page.currPage;
                        this.pageSize = response.data.page.pageSize;
                        this.tableData = response.data.page.list.map((item) => {
                            item.vesselname=item.vesselname+' / '+item.voyageno;
                            item.webtype=item.webtype.split('|')[0];
                            item.total = item.totalpackno+' / '+item.totalweight+' / '+item.totalvolume;
                            return item
                        });
                    }
                })
        },

        edit(item) { //编辑舱单
            let id = nanoid(5);
            let path = {
                title: item ? "编辑舱单" : '新建舱单',
                id: id,
                data: item,
                pathName: 'CreateManifest'
            }
            this.$emit('menuShow', path);
        },
        getSelection(e) { //获取table组件传来的多选信息
            this.multipleSelection = e
        },


    },
}
