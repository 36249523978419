<template>
  <div class="the_table">
    <el-table ref="multipleTable" border stripe :data="tableData" tooltip-effect="dark"
              @selection-change="handleSelectionChange" :header-cell-style="{
            background: '#eef1f6',
            color: '#606278',
            'text-align':'center'}" :cell-style="{'text-align':'center'}">
      <slot name="first">
        <el-table-column fixed type="selection" width="55">
        </el-table-column>
      </slot>
      <el-table-column
          type="index"
          width="50">
      </el-table-column>
      <el-table-column v-for="item in tableHeader" :key="item.prop" :prop="item.prop" :label="item.label" align="left"
                       :width="item.width?item.width:'auto'">
        <template slot-scope="scope">
          <span v-if="item.prop === 'completionstatus'">{{ disposeStatus(scope.row.completionstatus) }}</span>
          <span v-else :class="item.prop">{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
      <slot name="todo">
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <template v-if="type===2">
<!--              <span class="update_span" @click="operator('agent_login',scope.row)">登录船代</span>-->
              <!--              <span class="update_span" @click="operator('agent_send',scope.row)">发送到船代</span>-->
<!--              <span class="update_span" @click="operator('agent_check',scope.row)">船代舱单校验</span>-->
<!--              <span class="update_span" @click="operator('agent_sync',scope.row)">船代舱单状态同步</span>-->
<!--              <span class="update_span" @click="operator('agent_declare',scope.row)">船代舱单申报</span>-->
<!--              <span class="delete_span" @click="operator('agent_delete',scope.row)">删除船代舱单</span>-->
            </template>
            <div>
              <span class="update_span" @click="operator('edit',scope.row)">编辑</span>
              <span class="delete_span" v-if="type!==-1" @click="showDelete(scope.row)">删除</span>
            </div>
          </template>
        </el-table-column>
        <!--        <template v-if="alreadySend">-->
        <!--          <el-table-column fixed="right" label="操作" width="160">-->
        <!--            <template slot-scope="scope">-->
        <!--              <span class="look_span" @click="operator('look',scope.row)">查看</span>-->
        <!--              <span class="update_span" @click="operator('edit',scope.row)">修改</span>-->
        <!--              <span class="delete_span" @click="toReturnDelete(scope.row)">删除</span>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--        </template>-->
      </slot>
      <!--      <el-dialog title="提示" :visible.sync="deleteDialogVisible1" width="30%" append-to-body center>-->
      <!--        <span>你确认要申请撤销该舱单数据吗？</span>-->
      <!--        <span slot="footer" class="dialog-footer">-->
      <!--                <el-button @click="deleteDialogVisible1 = false">取 消</el-button>-->
      <!--                <el-button type="primary" @click="operator('delete')">确 定</el-button>-->
      <!--            </span>-->
      <!--      </el-dialog>-->
    </el-table>
    <div class="pagination_div">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="sizes,prev, pager, next"
          :total="totalCount"
          :page-count="totalPage"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageSize"
          :current-page="currPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import DeleteManifestDialog from "./DeleteMainfestDialog.vue";
import LookDetailDialog from "./LookDetailDialog";
import ShanghaiChatDialog from "./ShanghaiChatDialog.vue";
import {mapGetters} from "vuex";
import {
  eleDelete,
  delectbillNo,
  querySelectGoods,
  agentLogin,
  agentSend,
  agentDelete,
  agentCheck,
  agentSync,
  agentDeclare,
} from "@/api/shanghaiManifest";

export default {
  components: {
    LookDetailDialog,
    DeleteManifestDialog,
    ShanghaiChatDialog
  },
  props: {
    tableHeader: {
      //列表标题
      type: Array,
      default: () => [],
    },
    tableData: {
      //列表数据
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: 0, //0:草稿箱，1：待发送，2：已发送
    },
    currPage: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    totalCount: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      deleteDialogVisible1: false,
      isDraft: false,
      isWaitSend: false,
      isAlreadySend: false,
      lookDetailDialog: false,
      waitSend: false,
      alreadySend: false,
      AllPackage: 0,
      AllWeight: 0,
      AllVolume: 0,
      DeletetableData: [], //删除的表格数据
      DeleteID: "",
      deleteDialogVisible: false,
      UpdateManifest: false,
      multipleSelection: [], //多选中的信息
      goodsData: [],
    };
  },
  computed: {
    disposeStatus() {
      return (status) => {
        let statusArr = {
          0: "暂存区",
          1: "待发送",
          2: "已发送",
        };
        return statusArr[status];
      };
    },
  },
  methods: {
    handleSelectionChange(val) {
      console.log('选择项发生变化', val);
      this.multipleSelection = val;
      this.$emit('fromChild', this.multipleSelection);
    }
    ,
    operator(type, item) {//edit,delete,copy
      if (type === 'look') {
        this.lookDetailDialog = true;
        this.$nextTick(() => {
          this.$refs.LookDetailDialog.init(item);
        })
      } else if (type === 'agent_login') {
        this.agentLogin(item.id);
      } else if (type === 'agent_send') {
        this.agentSend(item.id);
      } else if (type === 'agent_delete') {
        this.agentDelete(item.id);
      } else if (type === 'agent_check') {
        this.agentCheck(item.id);
      } else if (type === 'agent_sync') {
        this.agentSync(item.id);
      } else if (type === 'agent_declare') {
        this.agentDeclare(item.id);
      }
      this.$emit(type, item);
    }
    ,

    agentLogin(id) {
      agentLogin({id: id}).then((res) => {
        if (res.data.status) {
          this.$message(res.data.data);
        }
      })
    }
    ,
    agentSend(id) {
      agentSend({id: id}).then(() => {

      })
    }
    ,
    agentDeclare(id) {
      agentDeclare({id: id}).then((res) => {
        if (res.data.status) {
          this.$message.success("发送成功")
        }
      })
    }
    ,
    agentDelete(id) {
      agentDelete({id: id}).then((res) => {
        if (res.data.status) {
          this.$message.success("删除成功")
        }
      })
    }
    ,
    agentCheck(id) {
      agentCheck({id: id}).then((res) => {
        if (res.data.status) {
          this.$message.success("校验成功")
        } else {
          this.$message.error(res.data.data.msg);

        }
      })
    }
    ,
    agentSync(id) {
      agentSync({id: id}).then((res) => {
        if (res.data.status) {
          this.$message.success("同步成功")
        }
      })
    }
    ,
    refreshList() {
      this.$emit('refresh')
    },
    showDelete(item) {
      //删除舱单
      const deleteData = [];
      deleteData.push(item);
      this.$nextTick(() => {
        this.$refs.DeleteManifestDialog.init(deleteData);
      })
    }
    ,
    toReturnDelete(item) {//申请删除
      this.DeleteID = item.billno;
      this.deleteDialogVisible1 = true;
    }
    ,
    Delete() {
      let idlist = [this.DeleteID];
      eleDelete(idlist)
          .then((response) => {
            if (response.data.status == true) {
              this.$message({
                type: "success",
                message: "删除成功！"
              })
              this.$emit("queryDraft")
              this.$emit("queryWait")
            }
            this.$emit(type, item);
          })
    },
    Delete1() {
      delectbillNo({billno: this.DeleteID}).then((response) => {
        if (response.data.status == true) {
          this.$message({
            type: "success",
            message: "删除成功，已暂存在待发送！",
          });
        }
        this.$emit("queryAlready");
        this.deleteDialogVisible1 = false;
      });
    },
    lookit(item) {
      //查看详情
      this.lookDetailDialog = true;
      this.$nextTick(() => {
        this.$refs.LookDetailDialog.init(item);
      });
    },
    handleSizeChange(val) {
      this.$emit("pageSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("nextPage", val);
    },
  }

}

</script>

<style lang="stylus" scoped>
.update_span {
  color: blue;
  display: inline-block;
  margin-right: 1.5rem;
}

.delete_span {
  color: red;
}

.the_table {
  margin-top: 1rem;
}

.pagination_div {
  text-align: center;
  margin: 1rem;
}

.look_span,
.update_span,
.delete_span {
  cursor: pointer;
}

.look_span {
  color: green;
  display: inline-block;
  margin-right: 1.5rem;
}

.pagination_div {
  text-align: center;
  margin: 1rem;
}

.billno {
  font-weight: bold;
  color: var(--BLUE-3366FF);
}

.vesselname {
  font-weight: bold;
}

.total {
  text-align: center;
  display: block;
}
</style>
