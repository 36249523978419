<template>
    <el-dialog
        :visible.sync="dialogVisible"
        top="50px"
        width="1305px"
        class="rocket-fcw-chat-dialog"
        :close-on-click-modal="false"
    >
        <iframe
            ref="iframeRef"
            :src="`https://web.nbhuojian.com/chat/?openId=FCW_${userId}`"
            style="width: 1300px; height: 700px"
        ></iframe>
    </el-dialog>
</template>

<script>
export default {
    name: "ChatDialog",
    model: {
        prop: "visible",
        event: "update:visible",
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:visible"],
    data() {
        return {
            isFirstTime: true,
        };
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit("update:visible", val);
            },
        },
        userId() {
            return this.$store.state.user.userId;
        },
    },
    methods: {
        jumpRoom(roomId) {
            const jumpRoomByRoomId = () => {
                this.$nextTick(() => {
                    const iframeWindow = this.$refs.iframeRef.contentWindow;
                    iframeWindow.postMessage(
                        { type: window.btoa("jumpToRoomById"), data: roomId },
                        "https://web.nbhuojian.com/chat",
                    );
                });
            };
            if (this.isFirstTime) {
                this.isFirstTime = false;
                setTimeout(() => {
                    jumpRoomByRoomId();
                }, 2000);
            } else {
                jumpRoomByRoomId();
            }
        },
    },
};
</script>
<style lang="stylus">
.rocket-fcw-chat-dialog{
    .el-dialog__body{
        padding: 0;
        padding-top:10px;
    }
}
</style>
