<template>
  <div>
    <div class="input_div">
      <div v-if="type===0">
        <el-button type="primary" size="medium" @click="openManifestDialog()">新建舱单</el-button>
        <el-button type="primary" plain size="medium" @click="exportDialogVisible = true">表格导入/下载</el-button>
      </div>
      <div>
        <el-date-picker
            v-model="dateRange"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="setDate"
            :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div style="display:flex;">
        <el-input class="inline-input "
                  v-model="search.billno"
                  @keyup.enter.native="go2Search"
                  placeholder="请输入提单号(必填)"
                  clearable
                  maxlength="30">
        </el-input>
        <el-input class="inline-input "
                  v-model="search.vesselname"
                  @keyup.enter.native="go2Search"
                  placeholder="请输入船名"
                  clearable>
        </el-input>
        <el-button icon="el-icon-search" @click="go2Search"></el-button>
      </div>

      <div>
        <el-button type="warning" size="medium" @click="sendManifest" v-if="type===1">发送</el-button>
        <el-button type="primary" size="medium" plain @click="exportExcel()">导出</el-button>
        <el-button type="danger" size="medium" plain style="margin-right:1rem;" @click="toDelete()">删除</el-button>
      </div>
    </div>
    <el-dialog title="表格导入/下载" :visible.sync="exportDialogVisible" width="30%" center>
      <div style="text-align:center;">可先下载模板按照格式填写后上传</div>
      <el-upload style="margin:2rem auto;text-align:center;"
                 :file-list="fileList" :auto-upload="false" class="upload-demo" drag action=""
                 :on-change="fileUpload" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toDownload()">下载模板</el-button>
      </span>
    </el-dialog>
    <DeleteManifestDialog ref="DeleteManifestDialog" v-if="deleteDialogVisible" @refresh="go2Search">
    </DeleteManifestDialog>
  </div>
</template>

<script>
import DeleteManifestDialog from "./DeleteMainfestDialog.vue";
import {downloadExcelModule, exportShipExcel, importExcelModule, sendShMould} from "@/api/shanghaiManifest";
import moment from "moment";
import {sendHyManifestShHead} from "../../../api/shanghaiManifest";

export default {
  name: "ManifestSearchBar",
  components: {DeleteManifestDialog},
  props: {
    multipleSelection: {//选中的信息
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      default: 0//0:草稿箱，1：待发送，2：已发送
    }
  },
  data() {
    return {
      deleteDialogVisible: false,
      fileList: [],
      exportDialogVisible: false,
      search: {
        billno: '', //搜索的提单号
        vesselname: '', //搜索的船名
        voyageno: '', //搜索的航次
        sort: "updatetime_desc",
        startDate:'',
        endDate:''
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateRange: ''//日期时间范围
    }
  },
  methods: {
    go2Search() {
      this.$emit('search', this.search)
    },
    //下载舱单模板
    toDownload() {
      downloadExcelModule().then((res) => {
        if (res.status === 200) {
          this.saveFile(res.data, `上海舱单导入模板.xlsx`)
        }
      })
    },
    //导出列表
    exportExcel() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "warning",
          message: "只能选择一条舱单记录！"
        })
      } else if (this.multipleSelection.length === 1) {
        exportShipExcel(this.multipleSelection[0].id)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(response.data, `${this.multipleSelection[0].billno}${moment().format('YYYYMMDDHHmmss')}舱单.xlsx`)
              }
            })
      } else {
        this.$message({
          type: "warning",
          message: "请选择一条舱单记录！"
        })
      }
    },
    //保存文件
    saveFile(data, fileName) {
      let blob = new Blob([data], {
        type: "application/vnd.ms-excel;charset=utf-8"
      }); // res就是接口返回的文件流了
      let url = window.URL || window.webkitURL;
      let fileURL = url.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = fileURL;
      a.download = fileName;
      a.target = "_self";
      a.click();
      url.revokeObjectURL(fileURL);
    },
    openManifestDialog() { //打开舱单编辑或新建仓单
      this.$emit('edit')
    },
    //上传模板导入舱单
    fileUpload(file) {
      let filename = file.name
      let fileSuffix = filename.substr(filename.lastIndexOf('.') + 1);
      if (fileSuffix !== 'xlsx') {
        this.$message({
          type: "warning",
          message: "只允许上传xlsx文件！"
        })
        return false
      }
      importExcelModule({file: file.raw, id: this.$store.state.user.userId})
          .then((response) => {
            if (response.data.status) {
              this.$message({
                type: "success",
                message: "导入成功，数据已保存在草稿箱！"
              })
              this.exportDialogVisible = false;
              this.fileList = []
              this.$emit('search');
            }else{
              this.$message.error(response.data.message);
            }
          })
    },
    setDate(val){
      console.log(val)
      this.search.startDate=val[0]+' 00:00:00'
      this.search.endDate=val[1]+' 23:59:00'
    },
    //发送舱单
    sendManifest() {
      if (this.multipleSelection.length > 0) {
        const data = [];
        this.multipleSelection.forEach((item) => {
          data.push(item.id);
        })
        sendHyManifestShHead(data).then(response => {
          if (response.data.status) {
            this.$message({
              type: "success",
              message: "发送成功"
            })
          }
        })
      } else {
        this.$message({
          type: "error",
          message: "请选择要发送的舱单！"
        })
      }
    },
    //删除舱单
    toDelete() {
      if (this.multipleSelection.length > 0) {
        const deleteData = [];
        this.multipleSelection.forEach((item) => {
          deleteData.push(item);
        })
        this.deleteDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.DeleteManifestDialog.init(deleteData);
        })
      } else {
        this.$message({
          type: "error",
          message: "请选择要删除的舱单！"
        })
      }
    },
  },
}
</script>


<style scoped lang="stylus">
.input_div {
  margin: 1rem 0;
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
  background: var(--GRAY-f1f3f6);

  .inline-input {
    margin-right: 1rem;
  }
}
</style>
